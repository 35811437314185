import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import { VideoPlayer } from "@components/video"

import {
  ScrollToButton, LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Guitar Podcast | Fretful Dreams"
        ogDescription="Fretful Dreams is a conversation oriented podcast around the topic of guitars, with guitar builder Randy King and producer/artist Jimmy Ether."
        url="https://fretfuldreams.com"
      />
      <PageWrapper color="black">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
              <h1>Fretful Dreams: A Guitar Podcast</h1>
              <p>
                Fretful Dreams is a conversation oriented podcast around the topic of guitars, with guitar builder Randy King and producer/artist Jimmy Ether.
              </p>
							<p>
								We are available on most podcasting platforms including a video version on <a href="https://www.youtube.com/@FretfulDreams" target="_blank">YouTube</a>, and and audio only versions on most podcast platforms including: <a href="https://podcasts.apple.com/us/podcast/fretful-dreams/id1738980328" target="_blank">Apple Podcasts</a> and <a href="https://open.spotify.com/show/053PLAEtMVbyM4kvIBtgfd" target="_blank">Spotify</a>. Our RSS feed is available here: <a href="/feed.xml">RSS Feed</a>
							</p>
	          </div>
	        }
	        textRight={
	          <div>
            	<img src="/images/logo.png" alt="Guitar Podcast - Fretful Dreams" />
	          </div>
	        }
	      />
      </PageWrapper>
			<PageWrapper color="dark-yellow">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h2>The Wrong Mouth For Guitar - Fretful Dreams Episode 006</h2>
							<p>
								In this episode of Fretful Dreams, Randy King and Jimmy Ether talk about their introductions to playing music, the music posters they had in their rooms as kids, hair metal, and the Atlanta club load-ins in the 90s.
							</p>
							<iframe width="560" height="315" src="https://www.youtube.com/embed/IwNxozEka7c?si=hBwGgKQFAla0-gVB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="light-yellow">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h2>1960s and 1970s Japanese Guitars - Fretful Dreams Episode 005</h2>
							<p>
								In this episode of Fretful Dreams, Randy King and Jimmy Ether talk about their love of quirky 1960s and 1970s era Japanese made guitars.
							</p>
							<iframe width="560" height="315" src="https://www.youtube.com/embed/2pL-Z7zed_A?si=gRKLPhvatmRQiOJI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="dark-yellow">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h2>Getting Started Building Guitars - Fretful Dreams Episode 004</h2>
							<p>
								In this episode of Fretful Dreams, Jimmy Ether asks Randy King what he would recommend to anyone who wants to get into building guitars.
							</p>
							<iframe width="560" height="315" src="https://www.youtube.com/embed/7QgR_CWwjvo?si=4mLkAHr-eJoWpwoB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="light-yellow">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h2>Perfect Is Not Music - Recording Guitar - Fretful Dreams Episode 003</h2>
							<p>
								In the third episode of Fretful Dreams, Randy King and Jimmy Ether discuss their experiences and approach to recording guitar.
							</p>
							<iframe width="560" height="315" src="https://www.youtube.com/embed/TPaFPAR7j0o?si=35C38Xj-4RekPoR9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="dark-yellow">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h2>To All The Guitar Amplifiers We've Loved Before - Fretful Dreams Episode 002</h2>
							<p>
								In the second episode of Fretful Dreams, Randy King and Jimmy Ether discuss the amplifiers they've owned and loved. Fender brown panel amps, octal tube amps, tweed circuits, JCM 800, etc.
							</p>
							<iframe width="560" height="315" src="https://www.youtube.com/embed/Rl-C-N2fhmk?si=8r-tPi1c1PQY6eUy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="light-yellow">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h2>Was the Les Paul Designed by O. W. Appleton? - Fretful Dreams Episode 001</h2>
							<p>
								In this inaugural episode of Fretful Dreams, Randy King and Jimmy Ether discuss the historical significance of guitar designer O. W. Appleton and his likely contribution to the design of the Les Paul guitar model. They also discuss the Slingerland Songster Model 401 and the 1948 Bigsby Merle Travis Guitar.
							</p>
							<iframe width="560" height="315" src="https://www.youtube.com/embed/IouRwFXWxkw?si=6Li43315yZOFtfEb" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
	          </div>
	        }
	      />
			</PageWrapper>
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>Get in touch!</h2>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default IndexPage
